import LauralBadgeIcon from "./LauralBadgeIcon";
import "./LauralBadge.sass";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const LauralBadge = ({ setLauralBadgeModalOpen }) => {
    const lauralData = useSelector((state) => state.lauralBadge);
    const lauralDataYears = lauralData?.data ? Object.keys(lauralData.data) : [];
    const numberOfYears = lauralDataYears.length;
    const onModalView = !setLauralBadgeModalOpen;
    const badgeAreaRef = useRef(null);
    const minSize = Math.min(window.innerWidth, window.innerHeight * 1.77);
    const [badgeModalWidth, setBadgeModalWidth] = useState(minSize / (Math.ceil(numberOfYears / 2) * 2.3));
    const [badgeButtonAreaWidth, setBadgeButtonAreaWidth] = useState(window.innerWidth > 200 * numberOfYears ? 50 : window.innerWidth / (4 * numberOfYears));

    useEffect(() => {
        const handleResize = () => {
            const minSizeDynamic = Math.min(window.innerWidth, window.innerHeight * 1.77);
            setBadgeModalWidth(minSizeDynamic / (Math.ceil(numberOfYears / 2) * 2.3));
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [numberOfYears]);

    useEffect(() => {
        if (badgeAreaRef.current) {
            setBadgeButtonAreaWidth(window.innerWidth > 200 * numberOfYears ? 50 : window.innerWidth / (4 * numberOfYears));
        }
    }, [badgeAreaRef?.current?.getBoundingClientRect(), numberOfYears, onModalView]);

    if (numberOfYears > 0) {
        const calculateYearlyMetrics = (yearData) => {
            let totalSent = 0;
            let totalAnswered = 0;
            let totalCorrectAnswered = 0;
            let totalIncorrectAnswered = 0;

            Object.values(yearData).forEach((monthData) => {
                totalSent += monthData.total_sent || 0;
                totalAnswered += monthData.total_answered || 0;
                totalCorrectAnswered += monthData.correct_answered || 0;
                totalIncorrectAnswered += monthData.incorrect_answered || 0;
            });

            const engagement = Math.round(totalSent > 0 ? (totalAnswered / totalSent) * 100 : 0);
            const competency = Math.round(totalCorrectAnswered + totalIncorrectAnswered > 0 ? (totalCorrectAnswered / (totalCorrectAnswered + totalIncorrectAnswered)) * 100 : 0);

            return { engagement, competency, totalAnswered };
        };

        const innerComponent = (year, yearData) => {
            const currYearData = new Date().getFullYear() === parseInt(year);
            const currYearMultiplier = onModalView && currYearData ? 1.2 : 1;
            const { engagement, competency, totalAnswered: yearlyTotalAnswered } = calculateYearlyMetrics(yearData);

            return (
                <div className="badge-area" key={"laural-badge-" + year} ref={badgeAreaRef} style={{ width: badgeButtonAreaWidth }}>
                    <LauralBadgeIcon
                        data={yearData}
                        size={!onModalView ? 50 : badgeModalWidth * currYearMultiplier}
                        year={year}
                        engagement={engagement}
                        competency={competency}
                        yearlyTotalAnswered={yearlyTotalAnswered}
                        engLimit={lauralData.eng_limit}
                        compLimit={lauralData.comp_limit}
                        engLimitYear={lauralData.eng_limit_year}
                        compLimitYear={lauralData.comp_limit_year}
                        onModalView={onModalView}
                    />
                </div>
            );
        };

        if (!onModalView) {
            return (
                <div
                    type="button"
                    className="badge-component-button"
                    tabIndex={0}
                    onClick={() => setLauralBadgeModalOpen(true)}
                    onKeyDown={(e) => {
                        if (e.key === " " || e.key === "Enter") {
                            e.currentTarget.click();
                        }
                    }}
                >
                    {lauralDataYears.map((year) => innerComponent(year, lauralData.data[year]))}
                </div>
            );
        }
        return <div className="badge-component-modal">{lauralDataYears.map((year) => innerComponent(year, lauralData.data[year]))}</div>;
    } else {
        return null;
    }
};

export default LauralBadge;
