import { createSlice } from "@reduxjs/toolkit";

const initState = {};
const LauralBadgeSlice = createSlice({
    name: "badgeData",
    initialState: initState,
    reducers: {
        setBadgeData: (_state, { payload }) => {
            if (payload && typeof payload === "object" && Object.keys(payload).length > 0) {
                const tmpState = transformData(payload);
                return tmpState;
            } else {
                return {};
            }
        },
        reset: () => initState,
    },
});

const transformData = (data) => {
    const parseIfString = (value) => {
        if (typeof value === "string") {
            try {
                return JSON.parse(value);
            } catch (e) {
                return {};
            }
        }
        return value;
    };

    const { comp_limit, eng_limit, comp_limit_year, eng_limit_year, data: rawDataString } = parseIfString(data);

    const rawData = parseIfString(rawDataString);

    if (!Array.isArray(rawData)) {
        return {};
    }

    const transformedData = rawData.reduce((acc, item) => {
        const parsedItem = parseIfString(item);
        const { year, month, ...rest } = parsedItem;

        if (!acc[year]) {
            acc[year] = {};
        }

        acc[year][month] = rest;

        return acc;
    }, {});

    return {
        comp_limit: parseIfString(comp_limit),
        eng_limit: parseIfString(eng_limit),
        comp_limit_year: parseIfString(comp_limit_year),
        eng_limit_year: parseIfString(eng_limit_year),
        data: transformedData,
    };
};

export const { reset, setBadgeData } = LauralBadgeSlice.actions;
export default LauralBadgeSlice.reducer;
